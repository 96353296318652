/* header menu */
@import "../components/header-menu";

/* hero */
.hero {
  background-color: $gray;
  &__slider {
    position: relative;
      overflow: hidden;
  }
  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($white, .3);
    }
    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
      height: 0;
      overflow: hidden;
    }
    &-content {
      position: relative;
      z-index: 2;
      text-align: center;
      width: 100%;
      max-width: 660px;
      padding: 0 10px;
    }
    &-block {
      padding: 10px;
      background-color: rgba($white, .7);
      margin-bottom: 15px;
    }
    &-title {
      display: block;
      font-size: 30px;
      line-height: 34px;
      color: $default;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-top: -30px;
    }
    &-subtitle {
      display: block;
      color: $primary;
      font-size: 20px;
      line-height: 24px;
      font-weight: 300;
      padding-top: 10px;
      margin-bottom: 10px;
    }
    &-description {
      display: block;
      font-size: 14px;
      line-height: 19px;
      color: $default;
    }
    &-btn {}
  }
}

/* promo */
.promo {
  background-color: $light;
  &__item {
    &-picture {
      margin-bottom: 30px;
    }
    &-img {
      //height: 260px;
      margin: 0 auto;
      //object-fit: contain;
        object-fit: cover;
    }
    &-description {
      text-align: center;
    }
    &-title {
      font-size: 24px;
      line-height: 28px;
      font-weight: bold;
      color: $primary;
      margin-bottom: 20px;
    }
  }
}

/* news */
.news {
  &__bottom {
    text-align: center;
    padding-top: 30px;
  }
}

/* blog */
.blog {
  &__item {
    margin-bottom: 30px;
    &-link {
      display: flex;
      flex-direction: column;
      color: $default;
      &:hover {
        color: $primary;
      }
    }
    &-date {
      display: block;
      font-size: 13px;
      line-height: 17px;
      color: $gray;
      margin-bottom: 10px;
    }
    &-picture {
      display: block;
      position: relative;
      height: 0;
      overflow: hidden;
      padding-bottom: 56.25%;
    }
    &-img {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &-bottom {
      padding: 15px;
      background-color: $white;
    }
    &-title {
      font-size: 20px;
      line-height: 24px;
      font-weight: bold;
      color: inherit;
      padding-top: 5px;
      margin-bottom: 10px;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
    &-description {
      font-size: 14px;
      line-height: 21px;
      color: $gray;
      margin-bottom: 10px;
    }
    &-btn {
      display: inline-block;
      font-size: 14px;
      line-height: 21px;
      color: $primary;
    }
  }
}

/* catalog */
.catalog {
    &__subcategory {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 40px;
        background-color: $white;
        &-item {
            display: block;
            text-align: center;
            color: $default;
            padding: 10px;
            width: 50%;
            background-color: $white;
            &:hover {
                color: $primary;
            }
        }
        &-picture {
            display: block;
            position: relative;
            height: 0;
            overflow: hidden;
            padding: 50% 0;
        }
        &-img {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: contain;
        }
        &-title {
            display: block;
            font-size: 13px;
            line-height: 17px;
            padding-top: 10px;
        }
    }
    &__info {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        background-attachment: fixed;
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($black,.8);
        }
        .container {
            position: relative;
            z-index: 2;
        }
        .section {
            &__title {
                color: $white;
                margin-bottom: 25px;
            }
        }
        &-text {
            text-align: center;
            p {
                font-size: 16px;
                line-height: 26px;
                color: $default-200;
                margin-bottom: 15px;
            }
        }
    }
    &__main {
        padding-top: 40px;
    }
    &__promo {
        &-block {
            padding: 50px 0;
            background-color: $white;
            border-bottom: 1px solid $gray-100;
            &:last-of-type {
                border-bottom: none;
            }
        }
        &-media {
            padding: 0 10px;
            margin-bottom: 20px;
        }
        &-picture {
            display: block;
            position: relative;
            height: 0;
            overflow: hidden;
            padding: 45% 0;
        }
        &-img {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        &-description {
            padding: 0 10px;
        }
        .section {
            &__title {
                color: $primary;
                text-align: left;
                margin-bottom: 20px;
            }
        }
        &-action {
            padding-top: 20px;
        }
    }
    &__features {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        background-attachment: fixed;
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($black,.8);
        }
        .container {
            position: relative;
            z-index: 2;
        }
        &-item {
            display: flex;
            padding: 20px 0;
        }
        &-picture {
            width: 50px;
            min-width: 50px;
            height: 50px;
        }
        &-img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        &-description {
            padding: 0 25px;
        }
        &-title {
            display: block;
            font-size: 20px;
            line-height: 30px;
            color: $white;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: 1px;
            margin-bottom: 15px;
        }
        &-text {
            display: block;
            font-size: 14px;
            line-height: 24px;
            color: $default-200;
        }
    }

  &__filter {
    text-align: center;
    margin-bottom: 30px;
  }
  &__content {}
  &__sort {
    margin-bottom: 30px;
    &-list {
      margin-bottom: 25px;
    }
    &-item {
      margin-bottom: 15px;
        .label {
            &__checkbox {
                white-space: nowrap;
            }
        }
    }
  }
  &__select {
    &-title {
      display: block;
      font-size: 13px;
      line-height: 19px;
      color: $gray-200;
      white-space: nowrap;
    }
  }
  &__pagination {
    padding: 30px 0;
    text-align: center;
    &-load {
      margin-bottom: 30px;
    }
  }
}

/* success */
.success {
    &__content {
        padding: 15px;
        background-color: $white;
    }
    &__title {
        font-size: 22px;
        color: $default;
        line-height: 26px;
        font-weight: 500;
        margin-bottom: 15px;
    }
    &__subtitle {
        font-size: 14px;
        line-height: 21px;
        color: $default;
        margin-bottom: 5px;
    }
    &__text {
        font-size: 14px;
        line-height: 21px;
        color: $gray;
        margin-bottom: 20px;
    }
    &__data {
        margin-bottom: 30px;
    }
    &__info {
        &-title {
            color: $default;
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 15px;
            font-weight: 500;
        }
    }
}

/* product */
@import "../components/product";

/* product item */
@import "../components/product-item";

/* filter */
@import "../components/filter";

/* pagination */
@import "../components/pagination";
