.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: $white;
  box-shadow: 0 2px 5px rgba($black, .05);
  z-index: 20;
  &__top {
    display: none;
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    width: 100vw;
    height: calc(100vh - 50px);
    border-top: 1px solid $gray-100;
    background-color: $white;
    z-index: 100;
    &.show {
      display: block;
    }
    &-wrapper {
      padding: 30px 0;
    }
  }
  &__bottom {
    &-wrapper {
      display: flex;
      align-items: center;
      height: 50px;
    }
    &-left {
      width: 15%;
    }
    &-center {
      width: 70%;
    }
    &-right {
      display: flex;
      justify-content: flex-end;
      width: 15%;
    }
  }


  &__nav {
    text-align: center;
    margin-bottom: 30px;
    &-link {
      display: block;
      width: 100%;
      font-size: 16px;
      color: $gray;
      padding: 8px 0;
      font-weight: 300;
    }
  }
  &__search {
    margin-bottom: 40px;
    &-form {
      position: relative;
    }
    .input {
      padding-right: 40px;
    }
    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      width: 30px;
      height: 30px;
      color: $gray;
      .icon {
        width: 14px;
        height: 14px;
      }
    }
      &-list {
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          background-color: $white;
          z-index: 10;
          max-height: 150px;
          overflow-y: auto;
      }
      &-item {}
      &-link {
          display: flex;
          padding: 10px;
          background-color: $white;
          &:hover {
              background-color: $light;
          }
      }
      &-img {
          display: block;
          width: 50px;
          min-width: 50px;
          height: 50px;
          object-fit: contain;
      }
      &-description {
          display: block;
          width: 100%;
          padding-left: 10px;
      }
      &-title {
          display: block;
          width: 100%;
          margin-bottom: 5px;
          font-size: 14px;
          line-height: 19px;
          color: $default;
          font-weight: bold;
          padding-top: 3px;
      }
      &-price {
          display: block;
          &__old {
              font-size: 12px;
              line-height: 17px;
              color: $gray;
              padding-left: 10px;
              text-decoration: line-through;
          }
          &__current {
              font-size: 14px;
              line-height: 17px;
              color: $secondary;
              font-weight: bold;
          }
      }
      &-empty {
          display: block;
          padding: 10px;
          text-align: center;
          font-size: 14px;
          line-height: 19px;
          color: $gray;
          font-style: italic;
      }
  }
  &__language {
    &-list {
      display: flex;
      justify-content: center;
    }
      &-item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          min-width: 30px;
          height: 30px;
          font-size: 12px;
          line-height: 19px;
          color: $gray;
          margin: 0 5px;
          background-color: $light;
          text-transform: uppercase;
          position: relative;
      }
      &-link {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
          min-width: 30px;
          height: 30px;
          font-size: 12px;
          line-height: 19px;
          color: $default;
          background-color: $white;
          text-transform: uppercase;
          &:hover {
              color: $white;
              background-color: $default;
          }
      }
  }
  &__logo {
    display: block;
    width: 140px;
    margin: 0 auto;
  }
  &__menu {
    &-btn {
        display: none;
    }
      &-mobile {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 50px;
          padding: 0 15px;
          font-size: 16px;
          line-height: 21px;
          color: $white;
          font-weight: 500;
          background-color: $secondary;
          margin-bottom: 20px;
      }
  }
  &__contacts {
    display: none;
  }
  &__user {
    &-wish {
      position: fixed;
      bottom: 20px;
      right: 15px;
      z-index: 10;
      .header__user {
        &-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 35px;
          height: 35px;
          color: $secondary;
          .icon {
            width: 27px;
            height: 27px;
          }
        }
        &-indicator {
          display: none;
        }
        &-counter {
          display: none;
        }
      }
    }
    &-cart {
      display: flex;
      align-items: center;
      color: $default;
    }
    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 35px;
      height: 35px;
      min-width: 35px;
      .icon {
        width: 23px;
        height: 23px;
      }
    }
    &-indicator {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: $secondary;
    }
    &-counter {
      display: block;
      font-size: 12px;
      line-height: 18px;
      padding-left: 5px;
    }
  }
  &__burger {
    display: block;
    width: 35px;
    min-width: 35px;
    height: 35px;
    background-color: transparent;
    cursor: pointer;
    position: relative;
    span {
      display: block;
      position: absolute;
      top: 17px;
      left: 4px;
      width: 26px;
      height: 2px;
      background-color: $default;
      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 26px;
        height: 2px;
        background-color: $default;
        transition: all 150ms ease;
      }
      &:before {
        top: -8px;
      }
      &:after {
        top: 8px;
      }
    }
    &.open {
      span {
        height: 0;
        &:before {
          transform: rotate(45deg);
          top: 0;
        }
        &:after {
          transform: rotate(-45deg);
          top: 0;
        }
      }
    }
  }
}
