// 375
@media screen and (min-width: 375px) {
  .catalog {
    &__sort {
      &-list {
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
      &-item {
        margin-bottom: 0;
        margin-left: 5px;
        margin-right: 5px;
      }
    }
    &__select {
      display: flex;
      align-items: center;
      &-title {
        margin-right: 20px;
      }
    }
  }
  .product {
    &__slider {
      &-picture {
        padding: 40% 0;
      }
    }
  }
}

// 426
@media screen and (min-width: 426px) {
  .hero {
    &__item {
      &-block {
        padding: 15px;
        margin-bottom: 35px;
      }
      &-title {
        font-size: 34px;
        line-height: 36px;
        margin-top: -35px;
      }
      &-subtitle {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
  .product {
    &__options {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__qty {
      width: 50%;
      max-width: 200px;
      padding-right: 15px;
      margin-bottom: 0;
    }
    &__select {
      width: 50%;
      padding-left: 15px;
    }
    &__actions {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    &__buy {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;
      max-width: 240px;
    }
    &__cost {
      display: flex;
      flex-direction: column;
      width: 50%;
      text-align: right;
      margin-bottom: 0;
    }
  }
}

// 476
@media screen and (min-width: 476px) {}

// 526
@media screen and (min-width: 526px) {
  .product {
    &__label {
      span {
        margin-top: 10px;
        font-size: 10px;
        padding: 6px 10px;
      }
    }
    &__whish {
      width: 40px;
      height: 40px;
      .icon {
        width: 22px;
        height: 22px;
      }
    }
    &__item {
      &-link {
        padding: 10px;
      }
      &-description {
        align-items: baseline;
        justify-content: space-between;
        flex-direction: row;
        padding: 5px 10px;
      }
      &-title {
        height: 40px;
      }
      &-name {
        font-size: 14px;
        line-height: 18px;
        font-weight: bold;
      }
      &-sku {
        min-width: 100px;
        text-align: right;
        padding-left: 5px;
      }
      &-buy {
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        padding: 10px;
      }
      &-btn {
        font-size: 13px;
        width: 50%;
      }
      &-cost {
        text-align: right;
        margin-bottom: 0;
        width: 50%;
      }
    }
    &__slider {
      &-link {
        padding: 20px;
      }
      &-thumbs {
        padding: 10px 10px 15px;
      }
    }
    &__description {
      padding: 20px;
    }
    &__price {
      font-size: 30px;
      &-old {
        font-size: 16px;
      }
    }
  }
}

// 575
@media screen and (min-width: 575px) {
    .catalog {
        &__subcategory {
            &-item {
                width: 25%;
            }
        }
    }
}

// 768
@media screen and (min-width: 768px) {
  .hero {
    &__item {
      &:after {
        padding-bottom: 75%;
      }
      &-block {
        padding: 20px;
        margin-bottom: 40px;
      }
      &-title {
        font-size: 50px;
        line-height: 50px;
        margin-top: -45px;
      }
      &-subtitle {
        font-size: 34px;
        line-height: 36px;
        margin-bottom: 20px;
      }
      &-description {
        font-size: 20px;
        line-height: 26px;
      }
    }
  }
  .promo {
    &__item {
      display: flex;
      align-items: center;
      &-picture {
        width: 50%;
      }
      &-img {
        //height: 340px;
      }
      &-description {
        width: 50%;
      }
      &-title {
        font-size: 30px;
        line-height: 34px;
      }
    }
  }
  .footer {
    &__logo {
      margin: 0;
    }
    &__item {
      text-align: left;
    }
    &__block {
      &-element {
        flex-direction: row;
        .icon {
          margin: 0;
        }
      }
      &-description {
        padding-left: 15px;
      }
    }
  }
  .product {
    &__slider {
      &-picture {
        padding: 30% 0;
      }
    }
  }
    .catalog {
        &__promo {
            &-block {
                &:nth-child(even) {
                    //background-color: $light;
                    .catalog__promo {
                        &-content {
                            flex-direction: row-reverse;
                        }
                    }
                }
            }
            &-content {
                display: flex;
                max-width: 1330px;
                margin: 0 auto;
            }
            &-media {
                width: 50%;
            }
            &-description {
                width: 50%;
            }
        }
    }
}















