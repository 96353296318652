.modal {
  &__container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
  }
  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    overflow: auto;
  }
  &__bg {
    display: none;
  }
  &__wrapper {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $light;
    padding: 10px;
    overflow: auto;
  }
  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: initial;
    position: absolute;
    top: 0;
    right: 0;
    opacity: .4;
    .icon {
      width: 18px;
      height: 18px;
    }
    &:hover {
      opacity: .6;
    }
  }
  &__title {
    font-size: 24px;
    line-height: 28px;
    padding-top: 10px;
      padding-left: 30px;
      padding-right: 30px;
    margin-bottom: 30px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}
