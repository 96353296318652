.product {
  &__label {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 10;
    span {
      display: block;
      margin-top: 3px;
      font-size: 8px;
      line-height: 8px;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: $white;
      font-weight: 500;
      padding: 3px 7px;
      text-align: center;
      min-width: 50px;
      &.is-sale {
        background-color: #d02e32;
      }
      &.is-novelty {
        background-color: #127749;
      }
      &.is-top {
        background-color: #e05e25;
      }
    }
  }
  &__whish {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 5px;
    right: 5px;
    color: $gray;
    background-color: $white;
    border-radius: 50%;
    z-index: 10;
    &:hover {
      color: $danger;
    }
    &.is-wish {
      color: $danger;
    }
    .icon {
      width: 20px;
      height: 20px;
    }
  }

  &__top {
    margin-bottom: 40px;
  }
  &__bottom {}
  &__slider {
    position: relative;
    margin-bottom: 10px;
    &-gallery {
      background-color: $white;
    }
    &-item {}
    &-link {
      display: block;
      padding: 10px;
    }
    &-picture {
      display: block;
      position: relative;
      height: 0;
      overflow: hidden;
      padding: 50% 0;
    }
    &-img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    &-thumbs {
      padding: 5px 5px 10px;
      background-color: $white;
      overflow: hidden;
      &__item {
        &.swiper-slide-thumb-active {
          .product__slider-thumbs__bullet {
            border-color: $primary;
          }
        }
      }
      &__bullet {
        display: block;
        position: relative;
        height: 0;
        overflow: hidden;
        padding: 50% 0;
        border: 1px solid $light;
      }
      &__img {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 90%;
        height: 90%;
        object-fit: contain;
        transform: translate(-50%, -50%);
      }
    }
  }

  &__description {
    padding: 10px;
    margin-bottom: 10px;
    background-color: $white;
  }
  &__title {
    display: block;
    font-size: 24px;
    line-height: 30px;
    font-weight: normal;
    margin-bottom: 15px;
  }
  &__subtitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  &__sku {
    font-size: 13px;
    line-height: 19px;
    color: $gray-200;
  }
  &__available {
    font-size: 13px;
    line-height: 19px;
    color: $secondary;
  }
  &__options {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid $light;
  }
  &__qty {
    margin-bottom: 20px;
    &-title {
      display: block;
      font-size: 13px;
      line-height: 19px;
      color: $default;
      margin-bottom: 10px;
    }
    &-counter {
      display: flex;
      align-items: center;
    }
  }
  &__select {
    &-title {
      display: block;
      font-size: 13px;
      line-height: 19px;
      color: $default;
      margin-bottom: 10px;
    }
  }
  &__actions {
    display: flex;
    flex-direction: column-reverse;
  }
  &__buy {
    text-align: center;
    .btn {
      width: 100%;
      height: 55px;
      padding: 0 30px;
      align-items: center;
      margin-bottom: 15px;
      .icon {
        position: static;
        width: 18px;
        height: 18px;
        margin-top: 0;
        margin-left: 10px;
      }
    }
  }
  &__cost {
    text-align: center;
    margin-bottom: 20px;
  }
  &__price {
    display: block;
    font-size: 26px;
    line-height: 1;
    font-weight: bold;
    color: $secondary;
    &-old {
      display: block;
      font-size: 14px;
      line-height: 1;
      margin-bottom: 5px;
      color: $gray;
      text-decoration: line-through;
    }
  }
  &__variants {
    display: flex;
    margin: 0 -5px;
    &-item {
      padding: 0 5px;
      width: 33.3%;
    }
    &-link {
      display: block;
      color: $default;
      text-align: center;
      background-color: $white;
      height: 100%;
      &:hover {
        color: $primary;
        box-shadow: 2px 4px 10px rgba($black, 0.1);
      }
    }
    &-picture {
      display: block;
      position: relative;
      height: 0;
      overflow: hidden;
      padding: 50% 0;
    }
    &-img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    &-title {
      display: block;
      font-size: 13px;
      line-height: 17px;
      padding-top: 5px;
      padding-bottom: 10px;
    }
  }

  &__tabs {
    margin-bottom: 40px;
  }
  &__chars {
    &-item {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 15px;
    }
    &-title {
      display: block;
      font-size: 14px;
      line-height: 19px;
      max-width: 80%;
      word-break: break-all;
    }
    &-dots {
      position: relative;
      flex-grow: 1;
      margin: 0 10px;
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: 3px;
        width: 100%;
        border-bottom: 1px dotted $default;
      }
    }
    &-text {
      display: block;
      font-size: 14px;
      line-height: 19px;
      max-width: 80%;
      word-break: break-all;
    }
  }
  &__features {
    &-item {
      margin-bottom: 30px;
    }
    &-title {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
    &-img {
      display: block;
      width: 35px;
      min-width: 35px;
      height: 35px;
      object-fit: contain;
    }
    &-name {
      display: block;
      font-size: 14px;
      line-height: 19px;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: $default;
      padding-left: 15px;
      width: 100%;
    }
    &-block {
      margin-bottom: 15px;
    }
    &-subtitle {
      font-size: 14px;
      line-height: 19px;
      font-weight: bold;
      color: $default;
      margin-bottom: 10px;
    }
    &-text {
      font-size: 14px;
      line-height: 19px;
      color: $gray;
      margin-bottom: 7px;
      padding-left: 15px;
      position: relative;
      &:before {
        content: "-";
        display: inline-block;
        padding-right: 10px;
      }
    }
  }

}