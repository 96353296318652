.filter {
  display: none;
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  width: 100vw;
  height: calc(100vh - 51px);
  border-top: 1px solid $gray-100;
  background-color: $white;
  overflow: scroll;
  z-index: 50;
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 40px;
    background-color: $white;
  }
  &.is-show {
    display: block;
  }
  &__top {
    padding-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    position: relative;
    &-title {
      display: block;
      font-size: 18px;
      line-height: 24px;
      font-weight: normal;
    }
    &-close {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 15px;
      right: 15px;
      color: $gray-200;
      .icon {
        width: 14px;
        height: 14px;
      }
    }
  }
  &__item {
    background-color: $white;
    margin-bottom: 20px;
    padding: 10px;
    &-block {
      margin-bottom: 25px;
      &:only-child {
        margin-bottom: 0;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 15px;
      .icon {
        width: 12px;
        height: 12px;
      }
      span {
        display: block;
        font-size: 16px;
        line-height: 21px;
        color: $primary;
        font-weight: 500;
      }
      &:hover {
        color: $primary;
      }
      &.is-hide {
        .icon {
          transform: rotate(-90deg);
        }
      }
    }
  }
  &__sort {
    &-item {
      margin-bottom: 10px;
    }
  }
  &__range {
    display: flex;
    &-item {
      width: 50%;
    }
    &-label {
      display: flex;
      align-items: center;
      span {
        display: block;
        width: 30px;
        min-width: 30px;
        padding: 0 5px;
        font-size: 14px;
        line-height: 14px;
        text-align: center;
      }
    }
    &-input {
      display: block;
      width: 100%;
      padding: 8px 12px;
      background-color: $light;
      font-size: 14px;
      color: $default;
    }
      &-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          padding: 0 10px;
          height: 34px;
          font-size: 13px;
          font-weight: 500;
          background-color: $gray-100;
          text-transform: uppercase;
          color: $default;
          margin-left: 10px;
          &:hover {
              color: $white;
              background-color: $secondary;
          }
      }
  }
  &__close {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    .btn {
      width: 100%;
    }
  }
    &__reset {
        margin-bottom: 20px;
        padding: 0 10px;
        .btn {
            width: 100%;
            padding: 0 15px;
        }
    }
    &__bubble {
        opacity: 0;
        visibility: hidden;
        padding: 20px 0;
        &.is-show {
            opacity: 1;
            visibility: visible;
        }
        &-content {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 12px 10px;
            position: fixed;
            width: 100%;
            left: 0;
            bottom: 45px;
            background-color: $default;
        }
        &-link {
            display: block;
            font-size: 13px;
            line-height: 15px;
            color: yellow;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
        &-text {
            display: block;
            font-size: 13px;
            line-height: 17px;
            margin-left: 20px;
            color: $white;
        }
    }
    &__tags {
        &-block {
            margin-bottom: 10px;
        }
        &-title {
            display: block;
            font-size: 14px;
            line-height: 19px;
            margin-bottom: 5px;
            color: $default;
        }
        &-list {
            display: flex;
            flex-wrap: wrap;
        }
        &-item {
            margin-right: 5px;
            margin-bottom: 5px;
        }
        &-link {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 13px;
            line-height: 17px;
            color: $white;
            height: 25px;
            padding: 0 10px;
            background-color: $default-100;
            .icon {
                width: 10px;
                height: 10px;
                margin-left: 5px;
            }
            &:hover {
                background-color: $black;
            }
        }
    }
}
