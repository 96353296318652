body {
  padding-top: 50px;
  //background-color: $light;
}

h1 {
  font-size: 20px;
  line-height: 24px;
  color: $default;
}

h2 {
  font-size: 16px;
  line-height: 21px;
  color: $default;
}

h3 {
  font-size: 16px;
  line-height: 21px;
  color: $default;
}

h4 {
  font-size: 16px;
  line-height: 21px;
  color: $default;
}

h5 {
  font-size: 14px;
  line-height: 20px;
  color: $default;
}

h6 {
  font-size: 14px;
  line-height: 20px;
  color: $default;
}

/* modal */
@import "../components/modal";