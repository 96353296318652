.product__item {
  background-color: $white;
  position: relative;
  margin-bottom: 10px;
  transition: all 150ms ease;
  &:hover {
    box-shadow: 2px 4px 10px rgba($black, 0.1);
  }
  &-top {
    position: relative;
  }
  &-bottom {}
  &-link {
    display: block;
    padding: 5px;
  }
  &-picture {
    display: block;
    position: relative;
    height: 0;
    overflow: hidden;
    padding: 50% 0;
  }
  &-img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &-description {
    display: flex;
    flex-direction: column-reverse;
    padding: 5px;
    background-color: $white;
  }
  &-title {
    display: block;
    color: $default;
    height: 38px;
    overflow: hidden;
    &:hover {
      color: $primary;
    }
  }
  &-name {
    font-size: 13px;
    line-height: 17px;
    font-weight: 500;
    color: inherit;
  }
  &-sku {
    display: block;
    font-size: 12px;
    line-height: 17px;
    color: $gray-200;
  }
  &-buy {
    display: flex;
    flex-direction: column-reverse;
    border-top: 1px solid $gray-100;
    padding: 5px;
    transition: all ease 150ms;
  }
  &-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    line-height: 1.1;
    font-weight: 300;
    text-align: center;
    width: 100%;
    height: 35px;
    position: relative;
    color: $white;
    background-color: $default;
    &:hover {
      background-color: darken($default, 10%);
    }
    .icon {
      width: 16px;
      min-width: 16px;
      height: 16px;
      margin-left: 10px;
    }
    &.is-disable {
      color: $gray;
      background-color: $gray-100;
      &:hover {
        color: $white;
        background-color: $gray;
      }
    }
  }
  &-cost {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 35px;
    margin-bottom: 5px;
  }
  &-price {
    display: block;
    font-size: 16px;
    line-height: 1;
    font-weight: bold;
    color: $secondary;
    &__old {
      display: block;
      font-size: 13px;
      line-height: 1;
      text-decoration: line-through;
      color: $default-200;
      margin-bottom: 5px;
    }
    &__unavailable {
      display: block;
      font-size: 13px;
      line-height: 1;
      color: $gray;
    }
  }

}