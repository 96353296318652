.menu {
  display: none;
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  width: 100vw;
  height: calc(100vh - 51px);
  border-top: 1px solid $gray-100;
  background-color: $light;
  z-index: 15;
  overflow-y: auto;
  &__show {
    display: block;
  }
  &__list {
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 100px;
      background-color: transparent;
    }
  }
  &__item {
    padding: 15px 40px 15px 15px;
    border-bottom: 1px solid $gray-100;
    position: relative;
    &.open {
      background-color: $white;
    }
    &-preview {
      display: none;
      pointer-events: none;
    }
    &-link {
        display: flex;
        align-items: center;
      &__content {

      }
    }
    &-img {
      display: block;
      width: 30px;
      height: 30px;
      object-fit: contain;
    }
    &-title {
      display: block;
      width: 100%;
      font-size: 14px;
      line-height: 18px;
      font-weight: bold;
      color: $primary;
    padding-left: 15px;
    }
    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 15px;
      right: 5px;
      width: 30px;
      height: 30px;
      background-color: transparent;
      color: $gray;
      .icon {
        width: 12px;
        height: 12px;
      }
      &.active {
        transform: rotate(90deg);
      }
    }
    &-subtitle {
      display: none;
    }
  }
  &__subitem {
    display: none;
    &.show {
      display: block;
    }
    &-list {
      padding: 10px 0;
    }
    &-item {
      padding-left: 45px;
    }
    &-picture {
      display: none;
    }
    &-link {
      display: block;
      color: $gray;
      padding: 10px 0;
      &:hover {
        color: $primary;
      }
    }
    &-title {
      display: block;
      font-size: 14px;
      height: 19px;
    }
  }
}
