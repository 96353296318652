// arrows

.swiper-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  background-color: rgba($default,.2);
  background-image: none;
  border-radius: 50%;
  outline: none;
  transition: all 150ms ease;
  z-index: 2;
  .icon {
    width: 16px;
    height: 16px;
    fill: $white;
  }
  &:hover {
    background-color: rgba($default,.6);
  }
}
.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  left: 5px;
}
.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  right: 5px;
}

// disabled

.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
  opacity: 0;
  visibility: hidden;
}

// scroll bar

.swiper-container-horizontal > .swiper-scrollbar {
  left: 0;
  width: 100%;
  opacity: 1 !important;
  bottom: 0;
  height: 4px;
  background-color: $gray-100;
  z-index: 1;
}
.swiper-scrollbar-drag {
  background-color: $secondary;
}

// bullets

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
  display: inline-flex;
  width: auto;
  bottom: 10px;
  top: unset;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 4px;
}

.swiper-pagination-bullet {
  border-radius: 0;
  display: block;
  width: 40px;
  height: 4px;
  background-color: $white;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background-color: $default;
}
